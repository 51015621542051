import { useMemo } from 'react';
import { Box, BoxProps, HStack, IconButton } from '@chakra-ui/react';
import { FiArchive } from 'react-icons/fi';
import { generatePath, Link, useParams } from 'react-router-dom';
import FilterBadge from 'components/FilterBadge';
import useQueryParams from 'utils/useQueryParams';
import { SLUG, STATUS } from 'pages/LandingPage/Listing/constants';
import ROUTES from 'app/routes';

type FilterBoxProps = BoxProps;

function FilterBox({ children }: FilterBoxProps) {
  const { store } = useParams();
  const { search } = useQueryParams();

  const amount = useMemo(() => {
    let counter = 0;
    counter += search.get(STATUS) ? 1 : 0;
    counter += search.get(SLUG) ? 1 : 0;

    return counter;
  }, [search]);

  return (
    <HStack spacing="56px">
      <Box position="relative">
        {children}
        {amount > 0 && <FilterBadge amount={amount} />}
      </Box>
      <IconButton
        as={Link}
        to={{
          pathname: generatePath(ROUTES.landingPage.archive, {
            store,
          }),
        }}
        variant="ghost"
        aria-label="Archive"
        icon={<FiArchive />}
      />
    </HStack>
  );
}

export default FilterBox;
