import { Box } from '@chakra-ui/react';
import { Route, Outlet } from 'react-router-dom';
import map from 'lodash/map';
import DashboardListing from 'pages/Dashboard/Listing';
import DashboardArchive from 'pages/Dashboard/Archive';
import DashboardContainerCreate from 'pages/Dashboard/Containers/Create';
import StyleGuide from 'pages/StyleGuide';
import Login from 'pages/Login';
import PublicLayout from 'components/Layout/PublicLayout';
import Layout from 'components/Layout';
import ROUTES from 'app/routes';
import LandingPagesListing from 'pages/LandingPage/Listing';
import LandingPagesArchive from 'pages/LandingPage/Archive';
import Reset from 'pages/Reset';
import ChangePassword from 'pages/ChangePassword';
import CategoryListing from 'pages/Category/Listing';
import DashboardPreview from 'pages/Dashboard/Preview';
import CategoryCreate from 'pages/Category/Form/Create';
import DashboardContainerEdit from 'pages/Dashboard/Containers/Edit';
import CategoryEdit from 'pages/Category/Form/Edit';
import CategoryContainerListing from 'pages/CategoryContainer/Listing';
import CategoryContainerCreate from 'pages/CategoryContainer/Elements/Form/Create';
import SubcategoryListing from 'pages/Subcategory/Listing';
import SubcategoryCreate from 'pages/Subcategory/Form/Create';
import SubcategoryEdit from 'pages/Subcategory/Form/Edit';
import DashboardEdit from 'pages/Dashboard/Edit';
import CategoryTagListing from 'pages/CategoryTags/Listing';
import CategoryTagCreate from 'pages/CategoryTags/Form/Create';
import CategoryTagEdit from 'pages/CategoryTags/Form/Edit';
import CategoryTagPreview from 'pages/CategoryTags/Preview';
import LandingPageCreate from 'pages/LandingPage/Form/Create';
import CategoryContainerEdit from 'pages/CategoryContainer/Elements/Form/Edit';
import LandingPageEdit from 'pages/LandingPage/Form/Edit';
import DashboardSharePreview from 'pages/DashboardPreview';
import ShareDashboard from 'pages/ShareDashboard';
import Index from 'pages/Index';
import RegulationsListing from 'pages/Regulations/Listing';
import RegulationCreate from 'pages/Regulations/Form/Create';
import RegulationEdit from 'pages/Regulations/Form/Edit';
import RegulationElementsListing from 'pages/RegulationElements/Listing';
import RegulationElementCreate from 'pages/RegulationElements/Form/Create';
import RegulationElementEdit from 'pages/RegulationElements/Form/Edit';
import MinibannersListing from 'pages/Minibanners/Listing';
import MinibannerCreate from 'pages/Minibanners/Form/Create';
import MinibannerEdit from 'pages/Minibanners/Form/Edit';
import AdministrationListing from 'pages/Administration/Listing';
import AdministratorCreate from 'pages/Administration/Form/Create';
import AdministratorEdit from 'pages/Administration/Form/Edit';
import OnboardScreensListing from 'pages/OnboardScreens/Listing';
import OnboardScreenCreate from 'pages/OnboardScreens/Form/Create';
import OnboardScreenEdit from 'pages/OnboardScreens/Form/Edit';
import FaqsListing from 'pages/Faqs/Listing';
import FaqCreate from 'pages/Faqs/Form/Create';
import FaqEdit from 'pages/Faqs/Form/Edit';
import FaqPreview from 'pages/Faqs/Preview';
import AboutsListing from 'pages/Abouts/Listing';
import AboutCreate from 'pages/Abouts/Form/Create';
import AboutEdit from 'pages/Abouts/Form/Edit';
import AnnouncementsListing from 'pages/Announcements/Listing';
import AnnouncementCreate from 'pages/Announcements/Form/Create';
import AnnouncementEdit from 'pages/Announcements/Form/Edit';
import SplashScreensListing from 'pages/SplashScreens/Listing';
import SplashScreenCreate from 'pages/SplashScreens/Form/Create';
import SplashScreenEdit from 'pages/SplashScreens/Form/Edit';
import LeaderCategoriesListing from 'pages/LeaderCategories/Listing';
import LeaderCategoryCreate from 'pages/LeaderCategories/Form/Create';
import LeaderCategoryEdit from 'pages/LeaderCategories/Form/Edit';
import BenefitsScreensListing from 'pages/BenefitsScreens/Listing';
import BenefitsScreenCreate from 'pages/BenefitsScreens/Form/Create';
import BenefitsScreenEdit from 'pages/BenefitsScreens/Form/Edit';
import BenefitsScreensElementsListing from 'pages/BenefitsScreensElements/Listing';
import BenefitsScreensElementCreate from 'pages/BenefitsScreensElements/Form/Create';
import BenefitsScreenElementEdit from 'pages/BenefitsScreensElements/Form/Edit';
import SizingsListing from 'pages/Sizings/Listing';
import SizingCreate from 'pages/Sizings/Form/Create';
import SizingEdit from 'pages/Sizings/Form/Edit';
import NutritionsListing from 'pages/Nutritions/Listing';
import NutritionCreate from 'pages/Nutritions/Form/Create';
import NutritionEdit from 'pages/Nutritions/Form/Edit';
import TopSearch from 'pages/Statistics/TopSearch';
import TopBanners from 'pages/Statistics/TopBanners';
import ClothingMachinesListing from 'pages/ClothingMachines/Listing';
import ClothingMachineCreate from 'pages/ClothingMachines/Form/Create';
import ClothingMachineEdit from 'pages/ClothingMachines/Form/Edit';
import UsersListing from 'pages/Users/Listing';
import RatingsListing from 'pages/Ratings/Listing';
import InPostPoints from 'pages/InPostPoints/Listing';
import DhlPoints from 'pages/DhlPoints/Listing';
import BlogPostsListing from 'pages/BlogPost/Listing';
import BlogPostCreate from 'pages/BlogPost/Form/Create';
import BlogPostEdit from 'pages/BlogPost/Form/Edit';
import NotificationCategoriesListing from 'pages/NotificationCategories/Listing';
import NotificationCategoryCreate from 'pages/NotificationCategories/Form/Create';
import NotificationCategoryEdit from 'pages/NotificationCategories/Form/Edit';
import NotificationsListing from 'pages/Notifications/Listing';
import NotificationCreate from 'pages/Notifications/Form/Create';
import NotificationEdit from 'pages/Notifications/Form/Edit';
import TopBannersComparison from 'pages/Statistics/TopBannersComparison';
import WeatherCategoriesListing from 'pages/WeatherCategories/Listing';
import WeatherCategoryEdit from 'pages/WeatherCategories/Form/Edit';
import WeatherCategoryPreview from 'pages/WeatherCategories/Preview';
import WeatherConditionEdit from 'pages/WeatherConditions/Form/Edit';
import SubscriptionRunupScreensListing from 'pages/SubscriptionRunupScreens/Listing';
import SubscriptionRunupScreenCreate from 'pages/SubscriptionRunupScreens/Form/Create';
import SubscriptionRunupScreenEdit from 'pages/SubscriptionRunupScreens/Form/Edit';
import SubscriptionRunupScreenElementsListing from 'pages/SubscriptionRunupScreenElements/Listing';
import SubscriptionRunupScreenElementCreate from 'pages/SubscriptionRunupScreenElements/Form/Create';
import SubscriptionRunupScreenElementEdit from 'pages/SubscriptionRunupScreenElements/Form/Edit';
import AppStoriesInspirations from 'pages/AppStoriesInspirations/Listing';
import AppStoriesInspirationEdit from 'pages/AppStoriesInspirations/Form/Edit';
import TaxonomyContainerEdit from 'pages/CategoryContainer/Form/Edit';
import ShipmentsListing from 'pages/Shipments/Listing';
import ShipmentCreate from 'pages/Shipments/Form/Create';
import ShipmentEdit from 'pages/Shipments/Form/Edit';
import BasketballTeamsListing from 'pages/Basketball/Teams/Listing';
import BasketballTeamEdit from 'pages/Basketball/Teams/Form/Edit';
import BasketballPagesListing from 'pages/Basketball/Pages/Listing';
import SurveysListing from 'pages/Surveys/Listing';
import SurveyCreate from 'pages/Surveys/Form/Create';
import SurveyEdit from 'pages/Surveys/Form/Edit';
import SurveyQuestionsListing from 'pages/SurveyQuestions/Listing';
import SurveyQuestionCreate from 'pages/SurveyQuestions/Form/Create';
import SurveyQuestionEdit from 'pages/SurveyQuestions/Form/Edit';

export const renderRoutes = (routes: any[]) =>
  map(routes, ({ layout: RouteLayout = Box, ...route }, idx) => {
    const Component =
      typeof route.element === 'string' ? route.element : <route.element />;
    return (
      <Route
        key={`route-${route.path}-${idx}`}
        path={route.path}
        element={
          <RouteLayout>
            {Component}
            <Outlet />
          </RouteLayout>
        }
      >
        {route.children ? renderRoutes(route.children) : null}
      </Route>
    );
  });

export const PUBLIC_ROUTES = [
  { element: '404', path: '*', layout: PublicLayout },
  {
    element: Index,
    path: ROUTES.index,
    layout: PublicLayout,
  },
  { element: StyleGuide, path: ROUTES.styleGuide, layout: PublicLayout },
  { element: Login, path: ROUTES.auth.login, layout: PublicLayout },
  { element: Reset, path: ROUTES.auth.reset, layout: PublicLayout },
  { element: ChangePassword, path: ROUTES.auth.change, layout: PublicLayout },
  {
    element: DashboardSharePreview,
    path: ROUTES.dashboard.preview,
    layout: PublicLayout,
  },
];

export const PRIVATE_ROUTES = [
  {
    element: DashboardListing,
    path: ROUTES.dashboard.base,
    layout: Layout,
  },
  {
    element: DashboardArchive,
    path: ROUTES.dashboard.archive,
    layout: Layout,
  },
  {
    element: DashboardContainerCreate,
    path: ROUTES.dashboard.containers.create,
    layout: Layout,
  },
  {
    element: DashboardPreview,
    path: ROUTES.dashboard.containers.byId,
    layout: Layout,
  },
  {
    element: DashboardContainerEdit,
    path: ROUTES.dashboard.containers.edit,
    layout: Layout,
  },
  {
    element: DashboardEdit,
    path: ROUTES.dashboard.edit,
    layout: Layout,
  },
  {
    element: ShareDashboard,
    path: ROUTES.dashboard.share,
    layout: Layout,
  },
  {
    element: Index,
    path: ROUTES.root,
    layout: Layout,
  },
  {
    element: LandingPagesListing,
    path: ROUTES.landingPage.base,
    layout: Layout,
  },
  {
    element: LandingPagesArchive,
    path: ROUTES.landingPage.archive,
    layout: Layout,
  },
  {
    element: 'News banner',
    path: ROUTES.newsBanner.base,
    layout: Layout,
  },
  {
    element: CategoryListing,
    path: ROUTES.category.base,
    layout: Layout,
  },
  {
    element: CategoryCreate,
    path: ROUTES.category.create,
    layout: Layout,
  },
  {
    element: CategoryEdit,
    path: ROUTES.category.edit,
    layout: Layout,
  },
  {
    element: CategoryContainerListing,
    path: ROUTES.categoryContainer.base,
    layout: Layout,
  },
  {
    element: CategoryContainerCreate,
    path: ROUTES.categoryContainer.create,
    layout: Layout,
  },
  {
    element: SubcategoryListing,
    path: ROUTES.subcategory.base,
    layout: Layout,
  },
  {
    element: SubcategoryCreate,
    path: ROUTES.subcategory.create,
    layout: Layout,
  },
  {
    element: SubcategoryEdit,
    path: ROUTES.subcategory.edit,
    layout: Layout,
  },
  {
    element: CategoryTagListing,
    path: ROUTES.categoryTag.base,
    layout: Layout,
  },
  {
    element: CategoryTagCreate,
    path: ROUTES.categoryTag.create,
    layout: Layout,
  },
  {
    element: CategoryTagEdit,
    path: ROUTES.categoryTag.edit,
    layout: Layout,
  },
  {
    element: CategoryTagPreview,
    path: ROUTES.categoryTag.preview,
    layout: Layout,
  },
  {
    element: LandingPageCreate,
    path: ROUTES.landingPage.create,
    layout: Layout,
  },
  {
    element: CategoryContainerEdit,
    path: ROUTES.categoryContainer.edit,
    layout: Layout,
  },
  {
    element: LandingPageEdit,
    path: ROUTES.landingPage.edit,
    layout: Layout,
  },
  {
    element: MinibannersListing,
    path: ROUTES.minibanners.base,
    layout: Layout,
  },
  {
    element: MinibannerCreate,
    path: ROUTES.minibanners.create,
    layout: Layout,
  },
  {
    element: MinibannerEdit,
    path: ROUTES.minibanners.edit,
    layout: Layout,
  },
  {
    element: AdministrationListing,
    path: ROUTES.administration.base,
    layout: Layout,
  },
  {
    element: AdministratorCreate,
    path: ROUTES.administration.create,
    layout: Layout,
  },
  {
    element: AdministratorEdit,
    path: ROUTES.administration.edit,
    layout: Layout,
  },
  {
    element: RegulationsListing,
    path: ROUTES.regulations.base,
    layout: Layout,
  },
  {
    element: RegulationCreate,
    path: ROUTES.regulations.create,
    layout: Layout,
  },
  {
    element: RegulationEdit,
    path: ROUTES.regulations.edit,
    layout: Layout,
  },
  {
    element: RegulationElementsListing,
    path: ROUTES.regulationElements.base,
    layout: Layout,
  },
  {
    element: RegulationElementCreate,
    path: ROUTES.regulationElements.create,
    layout: Layout,
  },
  {
    element: RegulationElementEdit,
    path: ROUTES.regulationElements.edit,
    layout: Layout,
  },
  {
    element: OnboardScreensListing,
    path: ROUTES.onboardScreens.base,
    layout: Layout,
  },
  {
    element: OnboardScreenCreate,
    path: ROUTES.onboardScreens.create,
    layout: Layout,
  },
  {
    element: OnboardScreenEdit,
    path: ROUTES.onboardScreens.edit,
    layout: Layout,
  },
  {
    element: FaqsListing,
    path: ROUTES.faqs.base,
    layout: Layout,
  },
  {
    element: FaqCreate,
    path: ROUTES.faqs.create,
    layout: Layout,
  },
  {
    element: FaqEdit,
    path: ROUTES.faqs.edit,
    layout: Layout,
  },
  {
    element: FaqPreview,
    path: ROUTES.faqs.preview,
    layout: Layout,
  },
  {
    element: AboutsListing,
    path: ROUTES.abouts.base,
    layout: Layout,
  },
  {
    element: AboutCreate,
    path: ROUTES.abouts.create,
    layout: Layout,
  },
  {
    element: AboutEdit,
    path: ROUTES.abouts.edit,
    layout: Layout,
  },
  {
    element: AnnouncementsListing,
    path: ROUTES.announcements.base,
    layout: Layout,
  },
  {
    element: AnnouncementCreate,
    path: ROUTES.announcements.create,
    layout: Layout,
  },
  {
    element: AnnouncementEdit,
    path: ROUTES.announcements.edit,
    layout: Layout,
  },
  {
    element: SplashScreensListing,
    path: ROUTES.splashScreens.base,
    layout: Layout,
  },
  {
    element: SplashScreenCreate,
    path: ROUTES.splashScreens.create,
    layout: Layout,
  },
  {
    element: SplashScreenEdit,
    path: ROUTES.splashScreens.edit,
    layout: Layout,
  },
  {
    element: LeaderCategoriesListing,
    path: ROUTES.leaderCategories.base,
    layout: Layout,
  },
  {
    element: LeaderCategoryCreate,
    path: ROUTES.leaderCategories.create,
    layout: Layout,
  },
  {
    element: LeaderCategoryEdit,
    path: ROUTES.leaderCategories.edit,
    layout: Layout,
  },
  {
    element: BenefitsScreensListing,
    path: ROUTES.benefitsScreens.base,
    layout: Layout,
  },
  {
    element: BenefitsScreenCreate,
    path: ROUTES.benefitsScreens.create,
    layout: Layout,
  },
  {
    element: BenefitsScreenEdit,
    path: ROUTES.benefitsScreens.edit,
    layout: Layout,
  },
  {
    element: BenefitsScreensElementsListing,
    path: ROUTES.benefitsScreenElements.base,
    layout: Layout,
  },
  {
    element: BenefitsScreensElementCreate,
    path: ROUTES.benefitsScreenElements.create,
    layout: Layout,
  },
  {
    element: BenefitsScreenElementEdit,
    path: ROUTES.benefitsScreenElements.edit,
    layout: Layout,
  },
  {
    element: SizingsListing,
    path: ROUTES.sizings.base,
    layout: Layout,
  },
  {
    element: SizingCreate,
    path: ROUTES.sizings.create,
    layout: Layout,
  },
  {
    element: SizingEdit,
    path: ROUTES.sizings.edit,
    layout: Layout,
  },
  {
    element: NutritionsListing,
    path: ROUTES.nutritions.base,
    layout: Layout,
  },
  {
    element: NutritionCreate,
    path: ROUTES.nutritions.create,
    layout: Layout,
  },
  {
    element: NutritionEdit,
    path: ROUTES.nutritions.edit,
    layout: Layout,
  },
  {
    element: TopSearch,
    path: ROUTES.statistics.topSearch,
    layout: Layout,
  },
  {
    element: TopBanners,
    path: ROUTES.statistics.topBanners,
    layout: Layout,
  },
  {
    element: ClothingMachinesListing,
    path: ROUTES.clothingMachines.base,
    layout: Layout,
  },
  {
    element: ClothingMachineCreate,
    path: ROUTES.clothingMachines.create,
    layout: Layout,
  },
  {
    element: ClothingMachineEdit,
    path: ROUTES.clothingMachines.edit,
    layout: Layout,
  },
  {
    element: UsersListing,
    path: ROUTES.users.base,
    layout: Layout,
  },
  {
    element: RatingsListing,
    path: ROUTES.ratings.base,
    layout: Layout,
  },
  {
    element: InPostPoints,
    path: ROUTES.inPostPoints.base,
    layout: Layout,
  },
  {
    element: DhlPoints,
    path: ROUTES.dhlPoints.base,
    layout: Layout,
  },
  {
    element: BlogPostsListing,
    path: ROUTES.blogPost.base,
    layout: Layout,
  },
  {
    element: BlogPostCreate,
    path: ROUTES.blogPost.create,
    layout: Layout,
  },
  {
    element: BlogPostEdit,
    path: ROUTES.blogPost.edit,
    layout: Layout,
  },
  {
    element: NotificationCategoriesListing,
    path: ROUTES.notificationCategories.base,
    layout: Layout,
  },
  {
    element: NotificationCategoryCreate,
    path: ROUTES.notificationCategories.create,
    layout: Layout,
  },
  {
    element: NotificationCategoryEdit,
    path: ROUTES.notificationCategories.edit,
    layout: Layout,
  },
  {
    element: NotificationsListing,
    path: ROUTES.notifications.base,
    layout: Layout,
  },
  {
    element: NotificationCreate,
    path: ROUTES.notifications.create,
    layout: Layout,
  },
  {
    element: NotificationEdit,
    path: ROUTES.notifications.edit,
    layout: Layout,
  },
  {
    element: TopBannersComparison,
    path: ROUTES.statistics.topBannersComparison,
    layout: Layout,
  },
  {
    element: WeatherCategoriesListing,
    path: ROUTES.weatherCategories.base,
    layout: Layout,
  },
  {
    element: WeatherCategoryEdit,
    path: ROUTES.weatherCategories.edit,
    layout: Layout,
  },
  {
    element: WeatherCategoryPreview,
    path: ROUTES.weatherCategories.preview,
    layout: Layout,
  },
  {
    element: WeatherConditionEdit,
    path: ROUTES.weatherCategories.conditions.edit,
    layout: Layout,
  },
  {
    element: SubscriptionRunupScreensListing,
    path: ROUTES.subscriptionRunupScreens.base,
    layout: Layout,
  },
  {
    element: SubscriptionRunupScreenCreate,
    path: ROUTES.subscriptionRunupScreens.create,
    layout: Layout,
  },
  {
    element: SubscriptionRunupScreenEdit,
    path: ROUTES.subscriptionRunupScreens.edit,
    layout: Layout,
  },
  {
    element: SubscriptionRunupScreenElementsListing,
    path: ROUTES.subscriptionRunupScreens.elements.base,
    layout: Layout,
  },
  {
    element: SubscriptionRunupScreenElementCreate,
    path: ROUTES.subscriptionRunupScreens.elements.create,
    layout: Layout,
  },
  {
    element: SubscriptionRunupScreenElementEdit,
    path: ROUTES.subscriptionRunupScreens.elements.edit,
    layout: Layout,
  },
  {
    element: AppStoriesInspirations,
    path: ROUTES.appStoriesInspirations.base,
    layout: Layout,
  },
  {
    element: AppStoriesInspirationEdit,
    path: ROUTES.appStoriesInspirations.edit,
    layout: Layout,
  },
  {
    element: TaxonomyContainerEdit,
    path: ROUTES.categoryContainer.categoryEdit,
    layout: Layout,
  },
  {
    element: ShipmentsListing,
    path: ROUTES.shipments.base,
    layout: Layout,
  },
  {
    element: ShipmentCreate,
    path: ROUTES.shipments.create,
    layout: Layout,
  },
  {
    element: ShipmentEdit,
    path: ROUTES.shipments.edit,
    layout: Layout,
  },
  {
    element: BasketballTeamsListing,
    path: ROUTES.basketball.teams.base,
    layout: Layout,
  },
  {
    element: BasketballTeamEdit,
    path: ROUTES.basketball.teams.edit,
    layout: Layout,
  },
  {
    element: BasketballPagesListing,
    path: ROUTES.basketball.pages.base,
    layout: Layout,
  },
  {
    element: SurveysListing,
    path: ROUTES.surveys.base,
    layout: Layout,
  },
  {
    element: SurveyCreate,
    path: ROUTES.surveys.create,
    layout: Layout,
  },
  {
    element: SurveyEdit,
    path: ROUTES.surveys.edit,
    layout: Layout,
  },
  {
    element: SurveyQuestionsListing,
    path: ROUTES.surveys.questions.base,
    layout: Layout,
  },
  {
    element: SurveyQuestionCreate,
    path: ROUTES.surveys.questions.create,
    layout: Layout,
  },
  {
    element: SurveyQuestionEdit,
    path: ROUTES.surveys.questions.edit,
    layout: Layout,
  },
];
