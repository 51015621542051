import {
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  FormControl,
} from '@chakra-ui/react';
import { useCallback } from 'react';
import { UseFieldArrayMove } from 'react-hook-form';
import Input from 'components/Form/Input';
import ColorPicker from 'components/Form/Color';
import DropzoneField from 'components/Form/Dropzone';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import LinkCreatorSection from 'components/LinkCreator';
import SectionTitle from 'components/Form/SectionTitle';
import ActivityFormSection from 'components/Container/Sections/ActivityFormSection';
import AccordionButton from 'components/Collapsible/AccordionButton';

interface AppstoryItemSectionProps {
  id: string;
  itemIndex: number;
  prepareFieldName: (name: string) => string;
  isEditMode: boolean;
  move: UseFieldArrayMove;
  remove: (index: number) => void;
}

function AppstoryItemSection({
  id,
  itemIndex,
  prepareFieldName,
  isEditMode,
  move,
  remove,
}: AppstoryItemSectionProps) {
  const fieldname = useCallback(
    (name: string) =>
      prepareFieldName(`elementsAttributes[${itemIndex}].${name}`),
    [itemIndex, prepareFieldName],
  );

  return (
    <AccordionItem border={0} overflow="visible">
      <AccordionButton
        id={id}
        index={itemIndex}
        move={move}
        label={`ELEMENT ${itemIndex + 1}`}
        remove={remove}
      />
      <AccordionPanel pl={0} pr={0} pt={12} pb={0} overflow="visible">
        <FormControl as="fieldset" mb={9} maxW={FORM_BODY_MAX_WIDTH}>
          <SectionTitle as="legend">Zdjęcie</SectionTitle>
          <DropzoneField
            name={fieldname('image')}
            title="Dodaj zdjęcie w formacie .png, .jpg (min. 96 px x 96 px)"
          />
        </FormControl>
        <FormControl as="fieldset" mb={9}>
          <Flex gap={4}>
            <Input label="Nagłówek" type="text" name={fieldname('title')} />

            <Box minW={136}>
              <Input
                label="Rozmiar tekstu (w pt)"
                defaultValue={12}
                type="number"
                name={fieldname('titleFontSize')}
              />
            </Box>
          </Flex>
          <ColorPicker
            name={fieldname('textColor')}
            label="Kolor tekstu"
            mt={6}
            maxW={FORM_BODY_MAX_WIDTH}
          />
        </FormControl>
        <LinkCreatorSection
          prepareFieldName={fieldname}
          isEditMode={isEditMode || false}
          hideListingHeader
        />
        <ActivityFormSection
          prepareFieldName={fieldname}
          title={`Aktywność ${itemIndex + 1} elementu`}
        />
      </AccordionPanel>
    </AccordionItem>
  );
}

export default AppstoryItemSection;
