import { Flex, VStack, Text } from '@chakra-ui/react';
import { BiImage } from 'react-icons/bi';
import CartIcon from 'components/Icons/Cart';

interface ProductPlaceholderProps {
  idx: number;
}

function ProductPlaceholder({ idx }: ProductPlaceholderProps) {
  return (
    <Flex
      key={`blank_product_image${+idx}`}
      bgColor="complementary.lightGrey"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      w="full"
    >
      <VStack
        bgColor="complementary.whiteGrey"
        justifyContent="center"
        alignItems="center"
        position="relative"
        h={128}
        w={104}
      >
        <BiImage size={32} />
        <Text textAlign="center" fontWeight={700} fontSize="12px" maxW={36}>
          Zdjęcie <br /> produktu
        </Text>
      </VStack>
      <CartIcon position="absolute" bottom={2} right={2} />
    </Flex>
  );
}

export default ProductPlaceholder;
