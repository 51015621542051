/* eslint-disable import/prefer-default-export */
import { BannerListing, ContainerObject } from 'types/common';
import { transformActivityDates } from 'utils/date';
import { transformLinksAttributesForPayload } from 'utils/helpers';

type LandingPagePayload = {
  countries: string[] | null;
  containersAttributes: { [key: string]: any }[];
  title: string;
};

const preparePayload = (data: LandingPagePayload) => {
  const containersAttributes = data.containersAttributes.map(
    (container, index) => {
      const transformedContainer = {
        ...transformActivityDates(
          transformLinksAttributesForPayload(container),
        ),
      };

      if (transformedContainer.elementsAttributes) {
        transformedContainer.elementsAttributes =
          transformedContainer.elementsAttributes.map(
            (element: ContainerObject, elementIndex: number) => {
              return transformActivityDates(
                transformLinksAttributesForPayload({
                  ...element,
                  position: elementIndex,
                  bannerListingsAttributes:
                    element.bannerListingsAttributes?.map(
                      (banner: BannerListing) => ({
                        ...banner,
                        _destroy: !element?.withListingHeader,
                      }),
                    ),
                }),
              );
            },
          );
      }

      return {
        ...transformedContainer,
        placement: index,
      };
    },
  );

  return {
    ...data,
    containersAttributes,
  };
};

export { preparePayload };
