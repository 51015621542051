import { Icon, IconProps } from '@chakra-ui/react';

function Cart(props: IconProps) {
  return (
    <Icon
      viewBox="0 0 24 24"
      width="24px"
      height="24px"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_3967_70964"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3967_70964)">
        <path
          d="M18 11.75V8C18 7.44772 17.5523 7 17 7H5.5C4.94772 7 4.5 7.44772 4.5 8V19.5C4.5 20.0523 4.94772 20.5 5.5 20.5H9.25"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M8 10V5.5C8 3.84315 9.34315 2.5 11 2.5V2.5C12.6569 2.5 14 3.84315 14 5.5V10"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M15.5 14V22"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M11.5 18L19.5 18"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
    </Icon>
  );
}

export default Cart;
