import * as yup from 'yup';
import { activityDatesSchema } from 'components/Container/schema';

export const BODY_SCHEMA = yup.object({
  image: yup.mixed().test({
    test(file, context) {
      if (!file) {
        return context.createError({
          message: 'Logo jest wymagane',
        });
      }
      return true;
    },
  }),
  icon: yup.mixed().nullable(),
  emoji: yup.string().nullable().required('Pole jest wymagane'),
  title: yup.string().nullable().required('Pole jest wymagane'),
  subtitle: yup.string().nullable(),
  ...activityDatesSchema,
  itemsAttributes: yup.array(
    yup.object({
      position: yup.number(),
      title: yup.string().required('Pole jest wymagane'),
    }),
  ),
});

export const DEFAULT_VALUES = {
  title: '',
  subtitle: '',
  image: null,
  icon: null,
  emooji: '',
  active: true,
  itemsAttributes: [
    {
      position: 0,
      title: '',
    },
  ],
};
