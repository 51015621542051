/* eslint-disable @typescript-eslint/naming-convention */
import { serialize } from 'object-to-formdata';
import { format } from 'date-fns';
import { generatePath } from 'react-router-dom';
import {
  FlareonProductResponse,
  QuarticonProductsResponse,
} from 'types/dashboardCreator';
import {
  convertKeysToSnakeCase,
  transformLinksAttributesForPayload,
} from 'utils/helpers';
import {
  TransformedDashboardsResponse,
  DashboardsResponse,
  GenderClone,
  BulkDelete,
  BulkStatusChange,
  BulkDateChange,
  DashboardContainerResponse,
  BulkDashboardElementDelete,
  BulkDashboardElementStatusChange,
  ContainersCloneToCountryRequest,
  ContainerPositionChangeRequest,
  SetElementPositionRequest,
  SetElementPositionResponse,
  FlareonProductsResponse,
  ProductsListResponse,
  DashboardContainerTransformedResponse,
  ContainerElement,
} from 'types/dashboard';
import { api } from 'app/services/api';
import { FLAREON_ROUTES, QUARTICON_ROUTES } from 'app/apiRoutes';
import ROUTES from 'app/routes';
import { BannerListing, ContainerObject } from 'types/common';
import { ApiResponse } from 'types/api';
import { transformActivityDates } from 'utils/date';
import { SERIALIZER_OPTIONS } from 'app/constants';
import { parseDefaultValues } from 'utils/containers';

enum FEED_KIND {
  ADULTS = 'adults',
  CHILDREN = 'children',
}

const preparePayload = (values: ContainerElement) => {
  const data = { ...transformActivityDates(values) };

  if (data.deleteVideo) {
    data.deleteVideo = '1';
  }

  if (data.elementsAttributes) {
    data.elementsAttributes = data.elementsAttributes.map(
      (element: ContainerObject, index: number) => {
        return {
          ...transformActivityDates(
            transformLinksAttributesForPayload(element),
          ),
          placement: index,
          bannerListingsAttributes: element.bannerListingsAttributes?.map(
            (banner: BannerListing) => ({
              ...banner,
              _destroy: !element?.withListingHeader,
            }),
          ),
        };
      },
    );
  }

  const transformedPayload = convertKeysToSnakeCase(
    transformLinksAttributesForPayload(data),
  );

  return serialize(
    transformedPayload,
    SERIALIZER_OPTIONS, // optional
    new FormData(), // required by serializer as third param
    'dashboard_container',
  );
};

export const dashboardApi = api.injectEndpoints({
  endpoints: (build) => ({
    dashboardContainer: build.query<
      DashboardContainerTransformedResponse,
      string | number
    >({
      query: (id) => `dashboard_containers/${id}`,
      keepUnusedDataFor: 10,
      transformResponse(response: DashboardContainerResponse) {
        return parseDefaultValues(
          response.data,
        ) as DashboardContainerTransformedResponse;
      },
      providesTags: (result) => [{ type: 'Dashboards', id: result?.id }],
    }),
    dashboards: build.query<TransformedDashboardsResponse, string>({
      query: (queryString) => `dashboard_containers${queryString}`,
      keepUnusedDataFor: 10,
      transformResponse(response: DashboardsResponse) {
        const data = response.data.map((container) => {
          if (container.elements?.length) {
            container.elementsAttributes = container.elements;
          }
          if (container.specialElements?.length) {
            container.specialElementsAttributes = container.specialElements;
          }
          return container;
        });
        return {
          data,
          totals: response.meta.pagination.objects,
        };
      },
      providesTags: ['Dashboards'],
    }),
    previewDashboard: build.query<
      TransformedDashboardsResponse,
      { queryString: string; store: string }
    >({
      query: ({ queryString, store }) => {
        return {
          url: `dashboard_containers${queryString}`,
          method: 'GET',
          headers: { store },
        };
      },
      keepUnusedDataFor: 10,
      transformResponse(response: DashboardsResponse) {
        const data = response.data.map((container) => {
          if (container.elements?.length) {
            container.elementsAttributes = container.elements;
          }
          if (container.specialElements?.length) {
            container.specialElementsAttributes = container.specialElements;
          }
          return container;
        });
        return {
          data,
          totals: response.meta.pagination.objects,
        };
      },
      providesTags: ['Dashboards'],
    }),
    deleteDashboard: build.mutation<void, number>({
      query: (id) => ({
        url: `dashboard_containers/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Dashboards'],
    }),

    fetchProductBySKU: build.query<FlareonProductResponse, string>({
      query: (sku: string) => FLAREON_ROUTES.productBySKU(sku),
      keepUnusedDataFor: 10,
      transformResponse({ data }) {
        if (data.items.length) {
          return data.items[0];
        }
        return data.items;
      },
    }),
    fetchProductsBySKU: build.query<any, string>({
      query: (queryString: string) => FLAREON_ROUTES.productsBySKU(queryString),
      keepUnusedDataFor: 10,
      transformResponse({ data }) {
        return data.items;
      },
    }),
    fetchChosenForYou: build.query<QuarticonProductsResponse[], string>({
      query: (deviceId: string) => QUARTICON_ROUTES.chosenForYou(deviceId),
      keepUnusedDataFor: 10,
      transformResponse({ data }) {
        return data.items;
      },
    }),
    createComponent: build.mutation<any, any>({
      query: (formValues) => {
        return {
          url: 'dashboard_containers',
          method: 'POST',
          body: preparePayload(formValues),
        };
      },
      invalidatesTags: ['Dashboards'],
    }),
    updateDashboard: build.mutation<void, any>({
      query: (payload) => {
        const { id } = payload;

        return {
          url: `/dashboard_containers/${id}`,
          method: 'PUT',
          body: preparePayload(payload),
        };
      },
      invalidatesTags: ['Dashboards'],
    }),
    cloneToGender: build.mutation<ApiResponse, GenderClone>({
      query: (payload) => {
        return {
          url: 'dashboard_containers/clone',
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: ['Dashboards'],
    }),
    bulkDelete: build.mutation<void, BulkDelete>({
      query: (payload) => {
        return {
          url: 'dashboard_containers/bulk_delete',
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: ['Dashboards'],
    }),
    bulkUpdate: build.mutation<void, BulkStatusChange | BulkDateChange>({
      query: (payload) => {
        return {
          url: 'dashboard_containers/bulk_update',
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: ['Dashboards'],
    }),
    elementBulkDelete: build.mutation<void, BulkDashboardElementDelete>({
      query: (params) => {
        const { payload, id } = params;
        return {
          url: `/dashboard_containers/${id}/elements/bulk_delete`,
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: 'Dashboards', id: arg.id },
      ],
    }),
    elementBulkUpdate: build.mutation<void, BulkDashboardElementStatusChange>({
      query: (params) => {
        const { payload, id } = params;
        return {
          url: `/dashboard_containers/${id}/elements/bulk_update`,
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: 'Dashboards', id: arg.id },
      ],
    }),
    shareDashboard: build.mutation<void, any>({
      query: (payload) => {
        const relativeUrl = generatePath(ROUTES.dashboard.preview, {
          gender: payload.gender.toString(),
          dateFrom: format(new Date(payload.dateFrom), 'yyyy-MM-dd'),
          dateTo: format(new Date(payload.dateTo), 'yyyy-MM-dd'),
          store: payload.store,
        });

        const formData = {
          url: `${process.env.REACT_APP_CMS_URL}${relativeUrl}`,
          emails: payload.emails,
          content: payload.content,
        };
        return {
          url: `/dashboard_containers/share`,
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: ['Dashboards'],
    }),
    updateContainerPosition: build.mutation<
      void,
      ContainerPositionChangeRequest
    >({
      query: ({ id, position, gender }) => {
        return {
          url: `/dashboard_containers/${id}/sort`,
          method: 'PUT',
          body: { position, gender },
        };
      },
      invalidatesTags: ['Dashboards'],
    }),
    cloneToCountry: build.mutation<void, ContainersCloneToCountryRequest>({
      query: ({ dashboardContainerIds, targetStore, currentStore }) => {
        return {
          url: '/dashboard_containers/country_clone',
          method: 'POST',
          headers: { store: targetStore },
          body: { dashboardContainerIds, store: currentStore },
        };
      },
      invalidatesTags: ['Dashboards'],
    }),
    importInstaFeed: build.query<
      { data: string },
      { containerId: number; gender: string }
    >({
      query: ({ containerId, gender }) => {
        const kind = ['girls', 'boys'].includes(gender)
          ? FEED_KIND.CHILDREN
          : FEED_KIND.ADULTS;
        return `/dashboard_containers/${containerId}/import_feed?kind=${kind}`;
      },
      keepUnusedDataFor: 10,
    }),
    setElementPosition: build.mutation<
      SetElementPositionResponse,
      SetElementPositionRequest
    >({
      query: ({ containerId, elementId, placement }) => {
        return {
          url: `dashboard_containers/${containerId}/elements/${elementId}/sort`,
          method: 'PUT',
          body: { placement },
        };
      },
      invalidatesTags: (response) => {
        return [
          { type: 'Dashboards', id: response?.dashboardContainerElement?.id },
        ];
      },
    }),
    updateElement: build.mutation<void, any>({
      query: ({ containerId, elementId, payload }) => {
        return {
          url: `dashboard_containers/${containerId}/elements/${elementId}`,
          method: 'PUT',
          body: payload,
        };
      },
    }),
    productImages: build.query<ProductsListResponse, string>({
      query: (queryLink) => `${process.env.REACT_APP_E_PRODUCTS}?${queryLink}`,
      keepUnusedDataFor: 10,
      transformResponse(response: FlareonProductsResponse) {
        return {
          products: response.data.items.slice(0, 3),
        };
      },
    }),
  }),
});

export const {
  useCreateComponentMutation,
  useDeleteDashboardMutation,
  useDashboardContainerQuery,
  useDashboardsQuery,
  usePreviewDashboardQuery,
  useCloneToGenderMutation,
  useBulkDeleteMutation,
  useBulkUpdateMutation,
  useElementBulkDeleteMutation,
  useElementBulkUpdateMutation,
  useUpdateDashboardMutation,
  useShareDashboardMutation,
  useUpdateContainerPositionMutation,
  useFetchProductBySKUQuery,
  useFetchProductsBySKUQuery,
  useLazyFetchProductBySKUQuery,
  useLazyImportInstaFeedQuery,
  useCloneToCountryMutation,
  useSetElementPositionMutation,
  useFetchChosenForYouQuery,
  useUpdateElementMutation,
  useProductImagesQuery,
  useLazyProductImagesQuery,
} = dashboardApi;
