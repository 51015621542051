import { Button, HStack, useDisclosure } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import isEmpty from 'lodash/isEmpty';
import {
  useLandingBulkDeleteMutation,
  useLandingUpdateStatusMutation,
} from 'app/services/landingPageApi';
import DeleteModal from 'components/DeleteModal';
import { getSelectedRows } from 'selectors/uiSelectors';
import { StatusType } from 'types/common';
import { useAppSelector } from 'utils/reduxHooks';

type FooterButtonsProps = {
  isArchive?: boolean;
};

function FooterButtons({ isArchive = false }: FooterButtonsProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const selectedRows = useAppSelector(getSelectedRows);
  const isMenuDisabled = isEmpty(selectedRows);
  const [bulkDelete] = useLandingBulkDeleteMutation();
  const [bulkUpdateStatus] = useLandingUpdateStatusMutation();

  const onDelete = () => {
    try {
      bulkDelete({
        landingPageIds: selectedRows,
      }).unwrap();
      toast.success('Pomyślnie usunięto kontenery');
      onClose();
    } catch (error) {
      toast.error('Wystąpił problem podczas usuwania landing page');
    }
  };

  const onStatusUpdate = async (state: StatusType) => {
    try {
      await bulkUpdateStatus({
        landingPageIds: selectedRows,
        state,
      }).unwrap();
      toast.success('Zmiany zostały zastosowane');
    } catch (err) {
      toast.error('Wystąpił problem');
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  return (
    <>
      <DeleteModal isOpen={isOpen} onClose={onClose} onAccept={onDelete}>
        Czy na pewno chcesz usunąć wybrane landing page?
      </DeleteModal>
      <HStack>
        <Button disabled={isMenuDisabled} variant="link" onClick={onOpen}>
          USUŃ
        </Button>
        {isArchive ? (
          <Button
            variant="link"
            onClick={() => onStatusUpdate('unactive')}
            isDisabled={isMenuDisabled}
          >
            PRZYWRÓĆ
          </Button>
        ) : (
          <Button
            variant="link"
            onClick={() => onStatusUpdate('archived')}
            isDisabled={isMenuDisabled}
          >
            ARCHIWIZUJ
          </Button>
        )}
      </HStack>
    </>
  );
}

FooterButtons.defaultProps = {
  isArchive: false,
};

export default FooterButtons;
