import { Box } from '@chakra-ui/react';
import { Link, generatePath, useParams } from 'react-router-dom';
import ROUTES from 'app/routes';
import Footer, { FOOTER_HEIGHT } from 'components/Footer';
import ListingLayout from 'components/Layout/ListingLayout';
import { NAVBAR_HEIGHT } from 'components/Layout/Navbar';
import LoadingIndicator from 'components/LoadingIndicator';
import SearchIndicator from 'components/Search/SearchIndicator';
import FooterButtons from 'pages/LandingPage/Listing/components/FooterButtons';
import Table from 'pages/LandingPage/Listing/components/Table';
import { SEARCH_KEY } from 'pages/LandingPage/Listing/constants';
import useQueryParams from 'utils/useQueryParams';
import {
  useDeleteLandingPageMutation,
  useLandingPagesQuery,
} from 'app/services/landingPageApi';

function LandingPagesArchive() {
  const { queryString, isSearchActive } = useQueryParams({
    page_size: '10',
    page: '1',
    order: 'DESC',
    'q[status_eq]': 'archived',
  });
  const { store } = useParams();

  const [, state] = useDeleteLandingPageMutation({
    fixedCacheKey: 'landingPage',
  });

  const {
    isFetching,
    data: { data: containers, totals } = { data: [], totals: 0 },
  } = useLandingPagesQuery(`${queryString}&main_list=true`);

  const { isLoading } = state;
  const showLoading = isFetching || isLoading;

  const additionalPadding = isSearchActive(SEARCH_KEY) ? '183px' : '128px';

  return (
    <ListingLayout
      title="Archiwum landing page"
      searchKey={SEARCH_KEY}
      placement="landing_page"
      hideFilter
      headerButtons={[
        {
          label: 'WRÓĆ NA LISTĘ',
          as: Link,
          to: {
            pathname: generatePath(ROUTES.landingPage.base, {
              store,
            }),
          },
        },
      ]}
    >
      {showLoading && <LoadingIndicator />}
      {!showLoading && (
        <Box>
          <SearchIndicator searchKey={SEARCH_KEY} />
          <Table
            data={containers}
            h={`calc(100vh - (${NAVBAR_HEIGHT} + ${FOOTER_HEIGHT} + ${additionalPadding}));`}
          />
        </Box>
      )}
      <Footer totals={totals} footerButtons={<FooterButtons isArchive />} />
    </ListingLayout>
  );
}

export default LandingPagesArchive;
