import { useCallback } from 'react';
import {
  AccordionItem,
  AccordionPanel,
  Box,
  FormControl,
} from '@chakra-ui/react';

import { UseFieldArrayMove } from 'react-hook-form';
import Input from 'components/Form/Input';
import ColorPicker from 'components/Form/Color';
import DropzoneField from 'components/Form/Dropzone';
import SectionTitle from 'components/Form/SectionTitle';
import LinkCreatorSection from 'components/LinkCreator';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import ActivityFormSection from 'components/Container/Sections/ActivityFormSection';
import AccordionButton from 'components/Collapsible/AccordionButton';
import Checkbox from 'components/Form/Checkbox';

interface ImageGalleryItemSectionProps {
  id: string;
  elementIndex: number;
  prepareFieldName: (name: string) => string;
  shouldRenderHeaders: boolean;
  isEditMode: boolean;
  move: UseFieldArrayMove;
  remove: (index: number) => void;
}

function ImageGalleryItemSection({
  id,
  elementIndex,
  prepareFieldName,
  shouldRenderHeaders,
  isEditMode,
  move,
  remove,
}: ImageGalleryItemSectionProps) {
  const fieldname = useCallback(
    (name: string) =>
      prepareFieldName(`elementsAttributes[${elementIndex}].${name}`),
    [elementIndex, prepareFieldName],
  );

  return (
    <AccordionItem border={0}>
      <AccordionButton
        id={id}
        index={elementIndex}
        move={move}
        label={`${elementIndex + 1} element`}
        remove={remove}
      />
      <AccordionPanel pl={0} pr={0} pt={12} pb={0} overflow="visible">
        <Box pl={0} pr={0} pt={4} pb={0}>
          {shouldRenderHeaders && (
            <FormControl as="fieldset">
              <SectionTitle as="legend">Nagłówek</SectionTitle>
              <Input
                label="Treść nagłówka"
                type="text"
                name={fieldname('title')}
                mb={8}
              />
              <ColorPicker
                name={fieldname('text_color')}
                label="Kolor tekstu"
                mb={8}
              />
            </FormControl>
          )}
          <FormControl as="fieldset" mb={9} maxW={FORM_BODY_MAX_WIDTH}>
            <SectionTitle as="legend">Zdjęcie</SectionTitle>

            <DropzoneField
              name={fieldname('image')}
              title="Dodaj zdjęcia w formacie .png, .jpg<br/>
                    (min. 148px x 192px i 244px x 348px)"
            />
          </FormControl>

          <FormControl as="fieldset" mb={9} maxW={FORM_BODY_MAX_WIDTH}>
            <SectionTitle as="legend">Plik mp4</SectionTitle>
            <DropzoneField name={fieldname('video')} type="video" />
            <Checkbox name={fieldname('deleteVideo')} label="Usuń plik mp4" />
          </FormControl>

          <LinkCreatorSection
            prepareFieldName={fieldname}
            isEditMode={isEditMode || false}
            hideListingHeader
          />

          <ActivityFormSection
            prepareFieldName={fieldname}
            title={`Aktywność ${elementIndex + 1} elementu`}
          />
        </Box>
      </AccordionPanel>
    </AccordionItem>
  );
}

export default ImageGalleryItemSection;
