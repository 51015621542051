import { serialize } from 'object-to-formdata';
import { SERIALIZER_OPTIONS } from 'app/constants';
import { api } from 'app/services/api';
import { OptionTypeResponse } from 'types/common';
import {
  TransformedLandingPages,
  LandingPageResponse,
  LandingPagesResponse,
  SlugResponse,
  BulkDelete,
  SlugParams,
  LandingPageImportParams,
  LandingPageTransformedResponse,
  BulkStatusChange,
  LandingPageContainerCopyParams,
} from 'types/landingPage';
import { convertKeysToSnakeCase } from 'utils/helpers';
import parseDefaultValues from 'pages/LandingPage/Form/Edit/parseDefaultValues';
import { preparePayload } from 'utils/landingPage';

export const landingPageApi = api.injectEndpoints({
  endpoints: (build) => ({
    landingPage: build.query<LandingPageTransformedResponse, string | number>({
      query: (id) => `landing_pages/${id}`,
      keepUnusedDataFor: 10,
      transformResponse(response: LandingPageResponse) {
        return parseDefaultValues(response.data);
      },
      providesTags: ['LandingPage'],
    }),
    landingPages: build.query<TransformedLandingPages, string>({
      query: (queryString) => `landing_pages${queryString}`,
      keepUnusedDataFor: 10,
      transformResponse(response: LandingPagesResponse) {
        return {
          data: response.data,
          totals: response.meta.pagination.objects,
        };
      },
      providesTags: ['LandingPage'],
    }),
    slugs: build.query<OptionTypeResponse, SlugParams>({
      query: ({ store }) => {
        if (store) {
          return {
            url: 'landing_pages/slugs',
            headers: { store },
          };
        }
        return 'landing_pages/slugs';
      },
      transformResponse(response: SlugResponse) {
        return {
          data: response.data.map((slug) => ({
            value: slug,
            label: slug,
          })),
        };
      },
    }),
    deleteLandingPage: build.mutation<void, number>({
      query: (id) => ({
        url: `landing_pages/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['LandingPage'],
    }),
    createLandingPage: build.mutation<any, any>({
      query: (data) => {
        const payload = convertKeysToSnakeCase(preparePayload(data));

        const formData = serialize(
          payload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'landing_page',
        );

        return {
          url: 'landing_pages',
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: ['LandingPage'],
    }),
    createLandingPageAsync: build.mutation<any, any>({
      query: (data) => {
        const { store } = data;

        const formData = serialize(
          convertKeysToSnakeCase(data),
          SERIALIZER_OPTIONS,
          new FormData(),
          'landing_page',
        );

        return {
          url: 'landing_pages/create_async',
          headers: { store },
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: ['LandingPage'],
    }),
    landingBulkDelete: build.mutation<void, BulkDelete>({
      query: (payload) => {
        return {
          url: 'landing_pages/bulk_delete',
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: ['LandingPage'],
    }),
    landingUpdateStatus: build.mutation<void, BulkStatusChange>({
      query: (payload) => {
        return {
          url: 'landing_pages/update_status',
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: ['LandingPage'],
    }),
    updateLandingPage: build.mutation<void, any>({
      query: (formValues) => {
        const { id } = formValues;
        const payload = convertKeysToSnakeCase(preparePayload(formValues));

        const formData = serialize(
          payload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'landing_page',
        );

        return {
          url: `landing_pages/${id}`,
          method: 'PUT',
          body: formData,
        };
      },
      invalidatesTags: ['LandingPage'],
    }),
    importLandingPage: build.mutation<void, LandingPageImportParams>({
      query: (payload) => {
        return {
          url: 'landing_pages/country_import',
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: ['LandingPage'],
    }),
    copyLandingPageContainer: build.mutation<
      void,
      LandingPageContainerCopyParams
    >({
      query: ({ landingPageId, id }) => ({
        url: `landing_pages/${landingPageId}/containers/${id}/clone`,
        method: 'POST',
      }),
      invalidatesTags: ['LandingPage'],
    }),
  }),
});

export const {
  useLandingPagesQuery,
  useDeleteLandingPageMutation,
  useSlugsQuery,
  useCreateLandingPageMutation,
  useCreateLandingPageAsyncMutation,
  useLandingBulkDeleteMutation,
  useLandingUpdateStatusMutation,
  useLandingPageQuery,
  useUpdateLandingPageMutation,
  useImportLandingPageMutation,
  useCopyLandingPageContainerMutation,
} = landingPageApi;
