import { useCallback, useEffect } from 'react';
import { UseFieldArrayMove, useFormContext, useWatch } from 'react-hook-form';
import {
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  FormControl,
} from '@chakra-ui/react';
import ButtonFormSection from 'components/Container/Sections/ButtonFormSection';
import HeaderFormSection, {
  COMPONENTS,
} from 'components/Container/Sections/HeaderFormSection';
import RadioBoxControl from 'components/Form/RadioBoxControl';
import ColorPicker from 'components/Form/Color';
import DropzoneField from 'components/Form/Dropzone';
import Input from 'components/Form/Input';
import SectionTitle from 'components/Form/SectionTitle';
import OptionalFormFieldset from 'components/OptionalFormFieldset';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import LinkCreatorSection from 'components/LinkCreator';
import ActivityFormSection from 'components/Container/Sections/ActivityFormSection';
import ToggleCheckbox from 'components/Form/ToggleCheckbox';
import AccordionButton from 'components/Collapsible/AccordionButton';
import TagsFieldset from 'components/TagsFieldset';

const SLIDE_TYPES: { [key: string]: string } = {
  image: 'Standardowy',
  video: 'Video',
};

interface CarouselItemFormSectionProps {
  itemIndex: number;
  prepareFieldName: (name: string) => string;
  isEditMode: boolean;
  move: UseFieldArrayMove;
  remove: (index: number) => void;
  id: string;
}

function CarouselItemFormSection({
  itemIndex,
  prepareFieldName,
  isEditMode,
  move,
  remove,
  id,
}: CarouselItemFormSectionProps) {
  const { control, setValue } = useFormContext();
  const type = useWatch({
    control,
    name: prepareFieldName(`elementsAttributes[${itemIndex}].type`),
    defaultValue: {},
  });

  const prepareFieldNameForSlide = useCallback(
    (index: number, name: string) =>
      prepareFieldName(`elementsAttributes[${index}].${name}`),
    [prepareFieldName],
  );

  const fieldname = useCallback(
    (name: string) =>
      prepareFieldName(`elementsAttributes[${itemIndex}].${name}`),
    [itemIndex, prepareFieldName],
  );

  const withSlideTitle: boolean = useWatch({
    control,
    name: fieldname('withSlideTitle'),
  });

  useEffect(() => {
    // for banner preview types on listing
    if (type === 'image') {
      setValue(fieldname('video'), null);
    }
  }, [type, fieldname, setValue]);

  useEffect(() => {
    if (!withSlideTitle) {
      setValue(prepareFieldName('description'), '');
    }
  }, [prepareFieldName, setValue, withSlideTitle]);

  return (
    <AccordionItem border={0}>
      <AccordionButton
        id={id}
        index={itemIndex}
        move={move}
        label={`SLAJD ${itemIndex + 1}`}
        remove={remove}
      />
      <AccordionPanel pl={0} pr={0} pt={12} pb={0} overflow="visible">
        <FormControl as="fieldset" mb={9}>
          <SectionTitle as="legend">
            Wybierz typ {itemIndex + 1} slajdu
          </SectionTitle>

          <Flex>
            {Object.keys(SLIDE_TYPES).map((slideType) => (
              <RadioBoxControl
                key={slideType}
                label={SLIDE_TYPES[slideType]}
                name={fieldname('type')}
                id={fieldname(`type_${slideType}_${itemIndex}`)}
                value={slideType}
              />
            ))}
          </Flex>
        </FormControl>

        {type === 'image' ? (
          <ToggleCheckbox
            label="Efekt parallaxy"
            name={fieldname('parallax')}
            mb="44px"
            maxW={FORM_BODY_MAX_WIDTH}
          />
        ) : null}

        <FormControl as="fieldset" mb={9} maxW={FORM_BODY_MAX_WIDTH}>
          <SectionTitle as="legend">
            {type === 'image' && 'Zdjęcie'}
            {type === 'video' && 'Video'}
          </SectionTitle>
          <DropzoneField
            key={type}
            type={type}
            name={fieldname(type || 'image')}
            title={
              type === 'video'
                ? 'Dodaj plik'
                : 'Dodaj zdjęcie w formacie .png, .jpg (min. 360 px x 528 px)'
            }
          />
        </FormControl>

        <Box maxW={FORM_BODY_MAX_WIDTH} mb="26px">
          <ColorPicker
            name={fieldname('navigationColor')}
            label="Kolor nawigacji"
          />
        </Box>

        <HeaderFormSection
          prepareFieldName={fieldname}
          components={[
            COMPONENTS.TITLE,
            COMPONENTS.SUBTITLE,
            COMPONENTS.COLORTXT,
            COMPONENTS.COLORBG,
          ]}
        />
        <ButtonFormSection
          prepareFieldName={fieldname}
          isEditMode={isEditMode || false}
        />
        <TagsFieldset prepareFieldName={fieldname} />

        <OptionalFormFieldset
          title="Tytuł slajdu (opcjonalnie)"
          sectionWidth={FORM_BODY_MAX_WIDTH}
          mb={4}
          isActive={withSlideTitle}
          name={prepareFieldNameForSlide(itemIndex, 'withSlideTitle')}
        >
          <Input
            name={prepareFieldNameForSlide(itemIndex, 'description')}
            defaultValue=""
            label="Tytuł slajdu"
            mb={6}
          />
        </OptionalFormFieldset>

        <LinkCreatorSection
          prepareFieldName={fieldname}
          isEditMode={isEditMode || false}
          hideListingHeader
        />

        <ActivityFormSection
          prepareFieldName={fieldname}
          title={`Aktywność slajdu ${itemIndex + 1}`}
        />
      </AccordionPanel>
    </AccordionItem>
  );
}

export default CarouselItemFormSection;
